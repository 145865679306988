import { useEffect, useState } from "react";
import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import Spinner from "../global/Spinner";
import { useAuth } from "src/context/AuthContext";
import { fetchPrivateOfficePage } from "src/service/cmsservice";
import type { PrivateOfficePageType } from "src/types/cms/privateOfficePageType";

type Props = {
  lng: string;
};

const PrivateOfficeButton = ({ lng }: Props) => {
  const [t, setT] = useState(() => (key: string) => key);
  const [i18nLoaded, setI18nLoaded] = useState(false); // Track i18next loading status
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [privateOfficePage, setPrivateOfficePage] =
    useState<PrivateOfficePageType | null>();

  // i18n lang switcher
  useEffect(() => {
    const loadTranslations = async () => {
      await i18next.use(HttpBackend).init({
        lng,
        fallbackLng: "de",
        debug: false,
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      });

      setT(() => i18next.t.bind(i18next));
      setI18nLoaded(true);
    };

    loadTranslations();
  }, [lng]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      // console.log("User token found, initiating verification...");
      try {
        const privateOfficePageR = await fetchPrivateOfficePage(lng, getToken);
        if (privateOfficePageR) {
          console.log("loaded private office page");
          setPrivateOfficePage(privateOfficePageR);
        } else {
          console.log("not loaded private office page");
          setPrivateOfficePage(null);
        }
      } catch (error) {
        console.error("Private office fetching failed:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (i18nLoaded) initialize();
  }, [i18nLoaded, lng, getToken]);

  if (!i18nLoaded) {
    return (
      <div className="w-full h-14 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <a
        href={`/${lng}/privateoffice`}
        className="flex items-center justify-center"
      >
        <button
          type="button"
          className={`border-black dark:border-white border-2  selection:bg-slate-500  inline-flex ${
            privateOfficePage?.attributes?.offerActive ? "ps-4" : "px-4 py-2"
          }`}
        >
          <div className="md:inline-flex justify-center items-center">
            <div
              className={`text-xl md:whitespace-nowrap md:text-2xl font-semibold ${
                privateOfficePage?.attributes?.offerActive ? "my-2" : ""
              }`}
            >
              {i18nLoaded ? <div>{t("menu.privateOffice")}<span className="mx-2">-</span><span className="text-lg">
                {t("menu.privateOfficeCurrentOffers")}
                </span>
                </div> : <Spinner />}
            </div>
          </div>
          {privateOfficePage?.attributes?.offerActive && (
            <div className="ms-0">
              <div className="overflow-hidden h-12 w-12">
                <div className="h-24 w-24 bg-red-600 text-white flex -rotate-45 translate-y-4 translate-x-1">
                  <div className="ms-4 w-full font-extrabold text-lg">%!!</div>
                </div>
              </div>
            </div>
          )}
        </button>
      </a>
    </div>
  );
};

export default PrivateOfficeButton;

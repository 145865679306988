// src/components/DayPass.tsx

import { AuthProvider } from "src/context/AuthContext";
import PrivateOfficeButton from "./PrivateOfficeButton"


type Props = {
  lng: string;
};

const PrivateOfficeButtonWrapper = ({ lng }: Props) => {
  return (
    <AuthProvider>
      <PrivateOfficeButton lng={lng} />
    </AuthProvider>
  );
};

export default PrivateOfficeButtonWrapper;
